<template>
  <div class="subabout">
    <div class="sub-banner bg" />
    <div class="wrapper">
      <div class="bread">
        <div class="icon"><i class="el-icon-caret-right" /></div>
        <div class="text">您的位置：首页  <i>></i>  <span>{{ title }}</span></div>
      </div>
      <div class="sub-menu">
        <a href="/baseHome/about" title="公司简介" class="on">{{ title }}</a>
        <a href="/baseHome/culture" title="企业文化">企业文化</a>
        <a href="/baseHome/honor" title="荣誉资质">荣誉资质</a>
        <a href="/baseHome/cooperation" title="合作共赢">合作共赢</a>
        <a href="/baseHome/joinus" title="加入我们">加入我们</a>
      </div>
    </div>
    <div class="sub-title">{{ title }}</div>
    <div class="introduce">
      <div class="img"><img src="../../assets/images/aboutimg1.jpg"></div>
      <div class="text"><p><span>辽宁山水通途物联智能科技有限公司</span>（简称山水物联）系中国500强山东山水水泥集团下属的智能联运数字化平台企业。</p>
        <p>公司成立于2020年12月，2021年7月正式运营，是辽宁省首批网络货运试点企业，在东北网络货运行业中，交易额，运量、整合车辆数量遥遥领先。公司依托传统货物运输资源，通过<span>互联网+数字化</span>技术引用，构建独具特色的物流互联网平台，创新电商物流与大宗商品运输新模式，<span>为客户提供全方位的数字化物流服务及智能化解决方案</span>。</p>
        目前，山水物联运输业务正由东北地区逐步向全国多省份辐射，已成功与<span>200</span>多家货主型企业建立了良好的合作关系。</div>
    </div>
    <div class="sub-title">山水物联平台的优势</div>
    <div class="wrapper advantage">
      <dl>
        <dt><img src="../../assets/images/ad1.jpg"></dt>
        <dd><h3>运力调度</h3><span>打造多维度融合运力池，整合运力资源、降低运输成本</span></dd>
      </dl>
      <dl>
        <dt><img src="../../assets/images/ad2.jpg"></dt>
        <dd><h3>业务合规</h3><span>业务真实，“五流合一”（数据流、轨迹流、票据流、合同流、资金流）</span></dd>
      </dl>
      <dl>
        <dt><img src="../../assets/images/ad3.jpg"></dt>
        <dd><h3>全程可视</h3><span>运输轨迹全程可视，运单状态实时更新，多端协同，实时跟踪。</span></dd>
      </dl>
      <dl>
        <dt><img src="../../assets/images/ad4.jpg"></dt>
        <dd><h3>极致服务</h3><span>个性化运输方案定制<br>客服团队24小时在线服务</span></dd>
      </dl>
      <dl>
        <dt><img src="../../assets/images/ad5.jpg"></dt>
        <dd><h3>利益多元</h3><span>提供整车租赁销售、ETC、油气、轮胎、仓储等多元服务<br>延伸车辆保险、金融等服务</span></dd>
      </dl>
    </div>
    <div class="appliGroups">
      <div class="wrapper">
        <div class="sub-title">适用客户群体</div>
        <dl>
          <dt><img src="../../assets/images/agicon1.png"></dt>
          <dd><h3>货主企业<div class="line" /></h3><span>发货成本高，层层分包、加价？<br>
            发货不及时，信息不对称，找车难？<br>
            货物安全没保障，运输全程盲点多？ </span></dd>
        </dl>
        <dl>
          <dt><img src="../../assets/images/agicon2.png"></dt>
          <dd><h3>货车司机<div class="line" /></h3><span>运费低，利润薄？   找货满、找货难？<br>
            结算慢，收款无保障？<br>
            无法提供正规发票？</span></dd>
        </dl>
        <dl>
          <dt><img src="../../assets/images/agicon3.png"></dt>
          <dd><h3>物流企业<div class="line" /></h3><span>物流网络覆盖不全，<br>
            无运输管理软件协作？ 物流经营难？<br>
            货主客户不知道运单、货品的状况？ </span></dd>
        </dl>
        <dl>
          <dt><img src="../../assets/images/agicon4.png"></dt>
          <dd><h3>特定运输企业<div class="line" /></h3><span> 运输途中扬洒，不在指定消纳场所倾倒<br>
            限定路线和限定车速，<br>
            人工统计运输趟数</span></dd>
        </dl>
      </div>
    </div>
    <div class="sub-title">可解决用户什么问题？</div>
    <div class="wrapper tab">
      <div class="tabTitle">
        <ul>
          <li :class="{active: n == 0}" @click="n = 0">货主企业</li>
          <li :class="{active: n == 1}" @click="n = 1">物流企业</li>
          <li :class="{active: n == 2}" @click="n = 2">货车司机</li>
        </ul>
      </div>
      <div v-show="n == 0" class="tab-item">
        <dl>
          <dt><img src="../../assets/images/tab1.png"></dt><dd><h3>运力直采 省钱省力省时</h3><span>企业可以通过山水物联无车承运平台对社会车辆进行车源整合与调度，聚集优化积累社会车源，山水物联无车承运平台可以记录车辆使用情况，可对司机运送情况进行评价，无车承运平台进行使用记录，录入优化，需再次使用同种车辆平台直接推送评价高的车辆，提升调度能力。</span></dd>
        </dl>
        <dl>
          <dt><img src="../../assets/images/tab1.png"></dt><dd><h3>运力直采 省钱省力省时</h3><span>企业可以通过山水物联无车承运平台对社会车辆进行车源整合与调度，聚集优化积累社会车源，山水物联无车承运平台可以记录车辆使用情况，可对司机运送情况进行评价，无车承运平台进行使用记录，录入优化，需再次使用同种车辆平台直接推送评价高的车辆，提升调度能力。</span></dd>
        </dl>
        <dl>
          <dt><img src="../../assets/images/tab1.png"></dt><dd><h3>运力直采 省钱省力省时</h3><span>企业可以通过山水物联无车承运平台对社会车辆进行车源整合与调度，聚集优化积累社会车源，山水物联无车承运平台可以记录车辆使用情况，可对司机运送情况进行评价，无车承运平台进行使用记录，录入优化，需再次使用同种车辆平台直接推送评价高的车辆，提升调度能力。</span></dd>
        </dl>
        <dl>
          <dt><img src="../../assets/images/tab1.png"></dt><dd><h3>运力直采 省钱省力省时</h3><span>企业可以通过山水物联无车承运平台对社会车辆进行车源整合与调度，聚集优化积累社会车源，山水物联无车承运平台可以记录车辆使用情况，可对司机运送情况进行评价，无车承运平台进行使用记录，录入优化，需再次使用同种车辆平台直接推送评价高的车辆，提升调度能力。</span></dd>
        </dl>
      </div>
      <div v-show="n == 1" class="tab-item">
        <dl>
          <dt><img src="../../assets/images/tab1.png"></dt><dd><h3>1运力直采 省钱省力省时</h3><span>企业可以通过山水物联无车承运平台对社会车辆进行车源整合与调度，聚集优化积累社会车源，山水物联无车承运平台可以记录车辆使用情况，可对司机运送情况进行评价，无车承运平台进行使用记录，录入优化，需再次使用同种车辆平台直接推送评价高的车辆，提升调度能力。</span></dd>
        </dl>
        <dl>
          <dt><img src="../../assets/images/tab1.png"></dt><dd><h3>1运力直采 省钱省力省时</h3><span>企业可以通过山水物联无车承运平台对社会车辆进行车源整合与调度，聚集优化积累社会车源，山水物联无车承运平台可以记录车辆使用情况，可对司机运送情况进行评价，无车承运平台进行使用记录，录入优化，需再次使用同种车辆平台直接推送评价高的车辆，提升调度能力。</span></dd>
        </dl>
        <dl>
          <dt><img src="../../assets/images/tab1.png"></dt><dd><h3>1运力直采 省钱省力省时</h3><span>企业可以通过山水物联无车承运平台对社会车辆进行车源整合与调度，聚集优化积累社会车源，山水物联无车承运平台可以记录车辆使用情况，可对司机运送情况进行评价，无车承运平台进行使用记录，录入优化，需再次使用同种车辆平台直接推送评价高的车辆，提升调度能力。</span></dd>
        </dl>
        <dl>
          <dt><img src="../../assets/images/tab1.png"></dt><dd><h3>1运力直采 省钱省力省时</h3><span>企业可以通过山水物联无车承运平台对社会车辆进行车源整合与调度，聚集优化积累社会车源，山水物联无车承运平台可以记录车辆使用情况，可对司机运送情况进行评价，无车承运平台进行使用记录，录入优化，需再次使用同种车辆平台直接推送评价高的车辆，提升调度能力。</span></dd>
        </dl>
      </div>
      <div v-show="n == 2" class="tab-item">
        <dl>
          <dt><img src="../../assets/images/tab1.png"></dt><dd><h3>2运力直采 省钱省力省时</h3><span>企业可以通过山水物联无车承运平台对社会车辆进行车源整合与调度，聚集优化积累社会车源，山水物联无车承运平台可以记录车辆使用情况，可对司机运送情况进行评价，无车承运平台进行使用记录，录入优化，需再次使用同种车辆平台直接推送评价高的车辆，提升调度能力。</span></dd>
        </dl>
        <dl>
          <dt><img src="../../assets/images/tab1.png"></dt><dd><h3>2运力直采 省钱省力省时</h3><span>企业可以通过山水物联无车承运平台对社会车辆进行车源整合与调度，聚集优化积累社会车源，山水物联无车承运平台可以记录车辆使用情况，可对司机运送情况进行评价，无车承运平台进行使用记录，录入优化，需再次使用同种车辆平台直接推送评价高的车辆，提升调度能力。</span></dd>
        </dl>
        <dl>
          <dt><img src="../../assets/images/tab1.png"></dt><dd><h3>2运力直采 省钱省力省时</h3><span>企业可以通过山水物联无车承运平台对社会车辆进行车源整合与调度，聚集优化积累社会车源，山水物联无车承运平台可以记录车辆使用情况，可对司机运送情况进行评价，无车承运平台进行使用记录，录入优化，需再次使用同种车辆平台直接推送评价高的车辆，提升调度能力。</span></dd>
        </dl>
        <dl>
          <dt><img src="../../assets/images/tab1.png"></dt><dd><h3>2运力直采 省钱省力省时</h3><span>企业可以通过山水物联无车承运平台对社会车辆进行车源整合与调度，聚集优化积累社会车源，山水物联无车承运平台可以记录车辆使用情况，可对司机运送情况进行评价，无车承运平台进行使用记录，录入优化，需再次使用同种车辆平台直接推送评价高的车辆，提升调度能力。</span></dd>
        </dl>
      </div>
    </div>
  </div>

</template>

<script>

export default {
  name: '',
  components: {
  },
  data() {
    return {
      title: '公司简介',
      n: 0
    }
  },

  mounted() {

  },
  methods: {

  }
}
</script>

<style lang="scss">
@import "./../../assets/scss/sub.scss";
.subabout {
  .bg{background:url(./../../assets/images/aboutbg.jpg) }
  .introduce{height: 562px; position: relative;
    .img{position: absolute; left: 50%; margin-left: -960px;}
    .text{position: absolute; left: 50%; top: 61px; margin-left: -109px; width: 707px; height: 442px; line-height: 39px; font-size: 16px; background: #fff; box-sizing: border-box; padding:35px 0 0 47px;
      span{ font-weight: bold;}
      p{padding-bottom: 30px;}
    }
  }
  .advantage{overflow: hidden;
    dl{ float: left; width: 580px; height: 167px; margin: 0 36px 33px 0; overflow: hidden; border:#e3e3e3 solid 1px;
      dt{ float:left;width: 243px; height: 167px; overflow: hidden;}
      dt img{width: 243px; height: 167px;transition: all .5s; -webkit-transition: all .5s; -moz-transition: all .5s; -ms-transition: all .5s; -o-transition: all .5s;}
      &:hover dt img{width: 268px; height: 184px; margin-top:-9px;margin-left:-9px;}
      dd{float:left; width: 270px; padding:0 0 0 35px;
        h3{ font-size: 20px; color: #222; font-weight: normal; padding:26px 0 15px;}
        span{ font-size: 16px; color: #666; line-height: 30px;}
      }
    }
    dl:nth-child(2n){margin-right: 0;}
  }
  .appliGroups{ height: 492px;background:url(./../../assets/images/agbg.jpg) no-repeat center center; margin-top: 40px;
    dl{ float: left; width: 279px; height: 260px; margin: 0 28px 33px 0; background:url(./../../assets/images/agbg1.png) no-repeat bottom center;
      dt{  text-align: center; padding-top: 5px; height: 72px;}
      dt img{transition: all .5s; -webkit-transition: all .5s; -moz-transition: all .5s; -ms-transition: all .5s; -o-transition: all .5s;}
      &:hover dt img{ margin-top: -10px;}
      dd{ text-align: center;
        h3{ font-size: 20px; color: #222; font-weight: normal; padding:7px 0 25px; height: 43px; line-height: 43px; position: relative;
          .line{position: absolute; top: 10px; height: 2px; width:36px;left: 50%; margin-left:-18px; background: #044ca8;}
        }
        span{ font-size: 15px; color: #666; line-height: 27px;}
      }
    }
    dl:nth-child(5){margin-right: 0;}
  }
  .tab{ padding-bottom: 60px;
    .tabTitle{
      ul{ height: 53px; color: #fff;
        li{ box-sizing: border-box; cursor: pointer; float: left; line-height: 53px; width: 33.333%; text-align: center; background: #727272; font-size: 20px; font-weight: bold; border-right: 2px solid #fff; transition: all .5s; -webkit-transition: all .5s; -moz-transition: all .5s; -ms-transition: all .5s; -o-transition: all .5s;
          &:hover{ background: #006cdb;}
          &.active{background: #006cdb;}
        }

      }
    }
    .tab-item{ overflow: hidden;
      dl{ float: left; width: 580px; height: 250px; overflow: hidden; border:#dbdbdb solid 1px; margin: 30px 34px 0 0;
        dt{float: left; width: 155px; text-align: center; padding-top: 45px;}
        dd{ float: left; width: 400px;
          h3{ font-size: 20px; color: #222; font-weight: normal; padding:19px 0 7px; height: 43px; line-height: 43px; border-bottom: #c7c7c7 dotted 1px;}
          span{ font-size: 16px; color: #666; line-height: 28px; padding-top:15px; display: block;}
        }
      }
      dl:nth-child(2n){margin-right: 0;}
    }
  }
}
</style>
